@media (min-width: 640px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -340%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

@media (min-width: 768px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -355%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

@media (min-width: 1024px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -300%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8be0a11cd211c156-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8be0a11cd211c156-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/0493781852cbf0ac-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/0493781852cbf0ac-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8c0fe73bdbebb91d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8c0fe73bdbebb91d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/1e3e4a3108c86bc3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8be0a11cd211c156-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__inter_fa66c6';
src: url(/_next/static/media/8be0a11cd211c156-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__inter_Fallback_fa66c6';src: local("Arial");ascent-override: 91.25%;descent-override: 22.74%;line-gap-override: 0.00%;size-adjust: 106.17%
}.__className_fa66c6 {font-family: '__inter_fa66c6', '__inter_Fallback_fa66c6'
}

